<template>
  <Dialog v-model:visible="display" style="width:500px"
          :header="(form.id?'编辑':'新增')+'农药'"
          @hide="$emit('close')" :modal="true">
    <div class="p-fluid">
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span
            style="color:red;margin-right: 4px;">*</span>肥料名称:</label>
        <div class="p-col">
          <InputText v-model="form.name"
                     :class="{'p-invalid':(v$.form.name.$invalid && submitted)}"></InputText>
          <span v-if="v$.form.name.required.$invalid && submitted" style="font-size: 12px;color: red">请输入名称！</span>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span
            style="color:red;margin-right: 4px;">*</span>肥料厂家:</label>
        <div class="p-col">
          <InputText v-model="form.mfr"
                     :class="{'p-invalid':(v$.form.mfr.$invalid && submitted)}"></InputText>
          <span v-if="v$.form.mfr.required.$invalid && submitted" style="font-size: 12px;color: red">请输入厂家！</span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="取消" class="p-button-text" @click="display=false"/>
      <Button @click="submit(v$.$invalid)">保存</Button>
    </template>
  </Dialog>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'addOrUpdateInsecticide',
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      display: false,
      form: {
        id: null,
        name: null,
        mfr: null,
      },
      submitted: false
    }
  },
  validations() {
    return {
      form: {
        id: {},
        name: {required},
        mfr: {required},
      }
    }
  },
  methods: {
    init(data) {
      this.display = true
      this.form = JSON.parse(JSON.stringify(data))
    },
    submit(isInvalid) {
      this.submitted = true
      if (isInvalid) {
        return
      }
      this.$http('/insecticide/addOrUpdate', 'post', this.form, () => {
        this.display = false
        this.$toast.add({severity: 'success', summary: !this.form.id ? '添加成功' : '修改成功', life: 3000})
      })
    }
  }
};
</script>

<style scoped>

</style>